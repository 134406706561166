<template>
  <div>
    <b-row>
      <b-col
        cols="4"
      >
        <b-form-input
          v-model="vin"
          placeholder="FIN"
        />
      </b-col>
      <b-col
        cols="8"
      >
        <b-button
          variant="primary"
          type="button"
          @click="searchFin"
        >
          Fin Suchen
        </b-button>
      </b-col>
      <b-col
        cols="12"
        style="margin-top:20px;"
      >
        <b-card
          v-for="obj in vinArray"
          :key="obj.bonus.id"
        >
          <h3>{{ obj.bonus.vin }} ({{ obj.bonus.dateFrom | moment("DD.MM. YYYY") }} - {{ obj.bonus.dateTo | moment("DD.MM. YYYY") }}) | {{ obj.user.firstName }} {{ obj.user.lastName }} ({{ obj.user.email }})</h3>
          <vue-json-pretty :data="obj.bonus" />
          <vue-json-pretty :data="obj.user" />
          <br>
          <br>
          <b-button
            v-if="obj.bonus.currentStatus<40"
            variant="primary"
            type="button"
            @click="deleteFin(obj.bonus.id)"
          >
            FIN löschen
          </b-button>
          <br>
          <br>
          <b-alert
            v-if="serverResponse"
            variant="danger"
            show
            style="padding:20px;"
          >
            {{ serverResponse }}
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BAlert,
} from 'bootstrap-vue'

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import bonusStoreModule from './backofficeStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAlert,
    VueJsonPretty,
  },
  setup(props, ctx) {
    const BONUS_STORE_MODULE = 'backoffice-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const vin = ref('')
    const vinArray = ref([])
    const serverResponse = ref('')

    const searchFin = () => {
      serverResponse.value = ''
      store.dispatch(`${BONUS_STORE_MODULE}/getVin`, vin.value)
        .then(response => {
          vinArray.value = response.data
        })
    }

    const deleteFin = id => {
      serverResponse.value = ''
      ctx.root.$bvModal
        .msgBoxConfirm('Bitte bestätigen!.', {
          title: 'FIN löschen?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store.dispatch(`${BONUS_STORE_MODULE}/deleteVin`, id)
              .then(response => {
                console.log(response)
                if (response.data === 'OK') {
                  searchFin()
                  vin.value = ''
                } else {
                  serverResponse.value = 'Löschen mit VIN Status nicht erlaubt.'
                }
              })
          }
        })
    }

    return {
      vin,
      vinArray,
      searchFin,
      deleteFin,
      serverResponse,
    }
  },
}
//
</script>
